.examenes-container {
  .card-body {
    justify-content: space-between;
  };
  .nota-alert{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    img {
      max-height: 70px;
    };
    .vr {
      margin: 0 1.5rem;
    };
  };
  h1{
    font-size: 3.5rem;
    margin-top: 2rem;
    align-self: center;
    text-align: center;
    color: #EE4266;
  }
};

.preguntas-container{
  width: 100%;
  margin-top: 1rem;
  text-align: center;
  .row {
    margin-top: 2rem;
    margin-bottom: 2rem;
  };
  .card-interna {
    border-color: black;
    border-width: 1px;
    margin: 2rem;
  };
  .header {
    .subtitle {
      font-weight: normal;
    };
  };
}
