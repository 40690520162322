.entrega-container {
  .status-alert {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    img {
      max-height: 70px;
    };
    .vr {
      margin: 0 1.5rem;
    };
  };
  .nota-alert{
    margin: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    img {
      max-height: 70px;
    };
    .vr {
      margin: 0 1.5rem;
    };
  };
  .tp-header {
    text-align: center;
    margin: 1rem 0 2rem 0;
  };
  .pruebas {
    background: var(--bs-gray-900);
    border-radius: var(--bs-border-radius-sm);
    margin-bottom: 1rem;
    width: 100%;
    div {
      span {
        margin-bottom: .5rem;
      };
      display: flex;
      flex-direction: column;
      color: var(--bs-gray-500);
      padding: 2rem;
    };
  };
  .download-icon-button {
    margin-left: auto;
  };
};

.entregas-container {
  .table-container {
    width: 100%;
  };
  .card-body {
    min-height: 603px;
    justify-content: space-between;
  };
  .error-with-retry,
  .no-data,
  .loading {
    margin-top: 9rem;
    padding: 1rem;
    padding-top: 0;
  };
};

.nueva-entrega-container {
  .card-body {
    min-height: 107px;
    form {
      fieldset {
        align-items: flex-start;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
    };
    button {
      min-width: 90px;
    };
    button,
    .form-control,
    .form-select {
      margin-bottom: calc(0.5rem + 21px);
    };
    .has-message {
      button,
      .form-control,
      .form-select {
        margin-bottom: 0;
      };
      .text-danger {
        margin-top: 0.5rem;
      };
      .text-success {
        margin-top: 0.5rem;
      };
    };
    .was-validated {
      .form-control:invalid,
      .form-select:invalid {
        margin-bottom: 0.5rem;
      };
    };
    .has-text {
      a:hover {
        color: var(--bs-gray-700);
      };
      .form-control,
      .form-select {
        margin-bottom: calc(0.5rem - 3px);
      };
    }
  };
};
