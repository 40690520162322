#not-found {
  height: 100vh;
  text-align: center;

  .error {
    .title {
      font-size: 13rem;
      color: var(--bs-info);
      font-weight: 600;
      text-align: center;
    }

    .subtitle {
      font-size: 2.5rem;
      font-weight: 500;
      color: var(--bs-dark);
      text-align: center;
    }

    .description {
      font-size: 1.5rem;
      font-weight: 300;
      color: var(--bs-dark);
      text-align: center;
    }
  }

  .image {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }

  .action {
    justify-content: center;
    margin-top: 0;
    margin-bottom: auto;
  }
}
