.col {
  margin-bottom: 1rem;
}

.card {
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .card-text > svg {
      font-size: 125px;
      margin: 2rem auto;
    };
  };
};
